<template>
    <div style="padding: 10px">
        <!--功能区域-->
        <div style="margin: 10px 0px;display: flex;justify-content: space-between;align-content: stretch;">
<!--            <el-select v-model="selectValue" clearable placeholder="选择要显示的数据" style="width: 300px" >-->
<!--                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">-->
<!--                </el-option>-->
<!--            </el-select>-->

            <el-switch
                    v-model="missCallEnable"
                    active-text="仅显示未接电话"
                    inactive-text="全部显示"
                    @change="load()">
            </el-switch>

            <div>
                <el-button @click="clearFilter" style="margin-right:30px;">清除所有筛选条件</el-button>
            <el-input v-model="search" placeholder="请输入电话号码进行查询" style="width: 300px;" clearable></el-input>
            <el-button type="primary" style="margin-left: 5px" :loading="page_loading" @click="load">查询</el-button>
            </div>
        </div>
        <el-table
                :data="tableData"
                ref="callRecordTable"
                v-loading="page_loading"
                border
                stripe
                row-key="id"
                lazy
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                style="width: 100%">
            <el-table-column
                    prop="call_init_time_format"
                    label="通话时间" min-width="100px">
            </el-table-column>
            <el-table-column
                    prop="call_duration"
                    label="通话时长(秒)">
            </el-table-column>
            <el-table-column
                    prop="caller.user_name"
                    label="员工姓名">
            </el-table-column>
            <el-table-column
                    prop="caller.team.team_name"
                    label="所属团队">
            </el-table-column>
            <el-table-column
                    prop="client.client_num"
                    label="客户号码">
            </el-table-column>
            <el-table-column
                    prop="client.client_name"
                    label="客户名字">
            </el-table-column>
            <el-table-column prop="call_label" label="标记"
                 :filters="[{text:'对方拒接',value:'label1'},{text:'无人接听',value:'label2'}
                 ,{text:'其他情况',value:'label3'},{text:'处理完成',value:'label4'},{text:'协商失败',value:'label5'},{text:'未标记',value:'未标记'}]"
                 :filter-method="call_label_filter"
            >
                <template #default="scopes">
                    <el-tag type="danger" v-if="scopes.row.call_label=='label1'" style="width: 80px;text-align:center;">对方拒接</el-tag>
                    <el-tag type="warning" v-else-if="scopes.row.call_label=='label2'" style="width: 80px;text-align:center;">无人接听</el-tag>
                    <el-tag v-else-if="scopes.row.call_label=='label3'" style="width: 80px;text-align:center;">其他情况</el-tag>
                    <el-tag type="success" v-else-if="scopes.row.call_label=='label4'"  style="width: 80px;text-align:center;">处理完成</el-tag>
                    <el-tag type="warning" effect="dark" v-else-if="scopes.row.call_label=='label5'"  style="width: 80px;text-align:center;">协商失败</el-tag>
                    <el-tag type="info" v-else  style="width: 80px;text-align:center;">未标记</el-tag>

                </template>
            </el-table-column>
            <el-table-column label="类型"
                :filters="[{text:'呼出',value:1},{text:'呼入',value:2},{text:'内部呼出',value:3}]"
                :filter-method="call_direction_filter">
                <template #default="scopes">
                    <el-tag effect="dark" v-if="scopes.row.call_direction==1" type="success">呼出</el-tag>
                    <el-tag effect="dark" v-if="scopes.row.call_direction==2" type="danger">呼入</el-tag>
                    <el-tag effect="dark" v-if="scopes.row.call_direction==3" type="warning">内部呼出</el-tag>

                </template>
            </el-table-column>

            <el-table-column label="备注">

                <template #default="scope">

                    {{remarkFilter(scope.row.call_remark)}}
                    <a class="el-link el-link--primary is-underline" style="font-size: 12px;"
                       v-if="isRemarkViewMore(scope.row.call_remark)"
                       @click="viewMoreRemark(scope.row.call_remark)">
                        <span class="el-link--inner">查看更多...</span>
                    </a>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="210">
                <template #default="scope">
                    <el-badge v-if="!isLabeled(scope.row)" is-dot class="item recordItem"><el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button></el-badge>
                    <el-badge v-else class="item recordItem"><el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button></el-badge>

                    <!--                    通话记录暂时不让删除-->
<!--                    <el-popconfirm title="确定删除吗？" @confirm="handleDelete(scope.row.call_id)">-->
<!--                        <template #reference>-->
<!--                            <el-button size="mini" type="danger">删除</el-button>-->
<!--                        </template>-->
<!--                    </el-popconfirm>-->
<!--                    <el-button size="mini" type="primary" plain @click="calldel(scope.row)">拨号</el-button>-->



                        <!--播放录音-->
                    <el-popover placement="left" :width="400" @hide="pauseAudio"
                                v-model:visible="scope.row.voiceVisible">
                        <template #reference>


                            <el-button size="mini" v-if="scope.row.record_exist"
                               type="primary" plain
                               @click="getVoice(scope.row)"
                               :loading="scope.row.voiceLoading">
                                查看录音
                            </el-button>
                            <el-button size="mini" v-else disabled
                                       type="primary" plain>查看录音</el-button>

                        </template>
                        <div>
                            <audio class="voiceAudio" :src="scope.row.srcUrl" id="wav-player"
                                   :disabled="!scope.row.record_exist"
                                   controls="controls" @error="wavError(scope.row)"></audio>
                        </div>
                    </el-popover>

                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 10px 0">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
            <!--显示更多备注-->
            <el-dialog title="备注" v-model="moreRemarkFormVisible" width="30%"  :before-close="closeMoreRemarkForm">
                <el-input type="textarea" rows="15" readonly v-model="moreRemarkText" placeholder="请输入备注"></el-input>
                <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="closeMoreRemarkForm">关闭</el-button>
              </span>
                </template>
            </el-dialog>
            <!--弹窗-->
            <el-dialog title="编辑备注" v-model="EditVisible" width="30%">
                <el-form model="Edit_data_form" label-width="120px">
                    <el-form-item label="电话号码">
                        <el-input v-model="Edit_data_form.client.client_num" style="width: 75%" disabled></el-input>
                    </el-form-item>

                    <el-form-item label="话务员">
                        <el-input v-model="Edit_data_form.caller.user_name" style="width: 75%" disabled></el-input>
                    </el-form-item>

                    <el-form-item label="选择状态">
                        <div>
                            <el-radio-group v-model="Edit_data_form.call_label" size="mini" >
                                <el-tooltip class="item recordItem" effect="dark" content="选择后会生成一条新的任务" placement="top">
                                    <el-radio label="label1" border :disabled="IsCall_label">对方拒接</el-radio>
                                </el-tooltip>
                                <el-tooltip class="item recordItem" effect="dark" content="选择后会生成一条新的任务" placement="top">
                                    <el-radio label="label2" border :disabled="IsCall_label">无人接听</el-radio>
                                </el-tooltip>

                            </el-radio-group>
                        </div>
                        <div style="margin-top: 20px">
                            <el-radio-group v-model="Edit_data_form.call_label" size="mini" >
                                <el-tooltip class="item recordItem" effect="dark" content="选择后会生成一条新的任务" placement="top">
                                    <el-radio label="label3" border :disabled="IsCall_label">其他情况</el-radio>
                                </el-tooltip>
                                <el-tooltip class="item recordItem" effect="dark" content="该任务已完成" placement="top">
                                    <el-radio label="label4" border :disabled="IsCall_label">处理完成</el-radio>
                                </el-tooltip>

                            </el-radio-group>
                        </div>
                        <div style="margin-top: 20px">
                            <el-radio-group v-model="Edit_data_form.call_label" size="mini" >
                                <el-tooltip class="item recordItem" effect="dark" content="协商失败代表该任务结束" placement="top">
                                    <el-radio label="label5" border :disabled="IsCall_label">协商失败</el-radio>
                                </el-tooltip>
                            </el-radio-group>
                        </div>

                    </el-form-item>


                    <el-form-item label="备注">
                        <el-input type="textarea" rows="6" v-model="Edit_data_form.call_remark" style="width: 75%" placeholder="请输入备注"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
          <span class="dialog-footer">
            <el-button @click="EditVisible = false">取 消</el-button>
            <el-button type="primary" @click="Edit_save()" :loading="editCallDataOKBtnLoading">确 定</el-button>
          </span>
                </template>
            </el-dialog>

        </div>
    </div>

</template>

<script>
    // @ is an alias to /src


    import request from "../../utils/request";

    export default {
        name: 'callrecord',
        data(){
            return{
                calldata_form:{

                },
                EditVisible:false,//弹窗默认是关闭的
                search:'',
                total: 1,
                currentPage: 1,
                pageSize:10,
                tableData: [],
                Edit_data_form:{},
                caller:{},
                IsCall_label:false,
                editCallDataOKBtnLoading:false,
                missCallEnable:false,

                page_loading:false,
                moreRemarkFormVisible:false,
                moreRemarkText:'',
                options: [
                    {
                        value: '1',
                        label: '显示未接电话',
                    },
                    {
                        value: '2',
                        label: '显示未拨通电话',
                    },
                ],
                selectValue:'',
            }
        },
        created() {
            this.load()
        },
        methods:{
            load() {
                this.page_loading = true
                let userJSON = sessionStorage.getItem("user")
                this.caller=userJSON
                request.post('/callrecord?pageNum='+this.currentPage+
                    '&pageSize='+this.pageSize+
                    '&search='+this.search+
                    '&misscall='+this.missCallEnable).then(res => {
                    if(res.code===200){
                        this.tableData = res.data.list //接收到的数据
                        //把时间戳转换成时间类型
                        for(let i =0;i<this.tableData.length;i++){
                            this.tableData[i].voiceLoaded=false
                            this.tableData[i].call_init_time_format=
                                this.getDate(this.tableData[i].call_init_time,"yyyy-MM-dd hh:mm:ss")
                        }
                        this.total = res.data.total
                    }else{
                        this.$message({
                            type:"error",
                            message:res.msg,
                        })
                    }
                    this.page_loading = false
                }).catch(e=> {
                    this.page_loading = false
                    this.$message({
                        type: "error",
                        message: '查询失败,服务器错误',
                    })
                })
            },
            wavError(row){
                row.record_exist=false
            },
            getVoice(row){
                row.voiceVisible=false
                if(!row.voiceLoaded){//如果录音加载过了就不要请求了
                    row.voiceLoading = true
                    request.post("/getvoice",row.record_path,{responseType:'blob'}).then(res=>{
                        if(res.headers.exist==='true'){
                            row.srcUrl = window.URL.createObjectURL(res.data)
                            row.record_exist=true
                            row.voiceVisible=true
                        }else{
                            row.record_exist=false
                            row.srcUrl=''
                        }
                        row.voiceLoaded=true
                        row.voiceLoading = false
                    }).catch(e=> {
                        row.voiceLoading = false
                        this.$message({
                            type: "error",
                            message: '获取录音文件失败,服务器错误',
                        })
                    })
                }else {
                    row.voiceVisible=true
                }
            },
            pauseAudio(){
                var elements = document.getElementsByClassName('voiceAudio');
                Array.prototype.forEach.call(elements, function (element) {
                    element.pause();
                });

            },
            displayMissedCall(){

            },
            isLabeled(row){
                // this.$message({
                //     type: "error",
                //     message: '查询失败,服务器错误',
                // })
                try{
                    if(row.call_label=='label1'||row.call_label=='label2'||row.call_label=='label3'
                        ||row.call_label=='label4'||row.call_label=='label5'){
                        return true
                    }else{
                        return false
                    }
                }catch (e) {
                    return false
                }

            },
            remarkFilter(remark){
                if(remark&&remark.length>30){
                    return remark.substring(0,30);
                }else {
                    return remark;
                }
            },
            isRemarkViewMore(remark){
                if(remark&&remark.length>30){
                    return true;
                }else {
                    return false;
                }
            },
            viewMoreRemark(remark){
                this.moreRemarkText=remark
                this.moreRemarkFormVisible=true

            },
            closeMoreRemarkForm(){
                this.moreRemarkFormVisible=false
            },

            handleEdit(row){
                this.Edit_data_form = JSON.parse(JSON.stringify(row))//深拷贝
                this.EditVisible = true

                if(this.isLabeled(row)){
                    this.IsCall_label=true;
                }
                else{
                    this.IsCall_label=false;
                }
            },
            /*handleDelete(call_id){
                request.delete("/callrecord/" + call_id).then(res =>{
                    if(res.code === 200){
                        this.$message({
                            type:"success",
                            message:"删除成功"
                        })
                    }
                    else{
                        this.$message({
                            type:"error",
                            message:res.msg
                        })
                    }
                    this.load()
                })

            },*/
            handleSizeChange(pageSize){//改变当前每一页的个数触发
                this.pageSize=pageSize
                this.load();
            },
            handleCurrentChange(pageNum){//改变当前页码触发
                this.currentPage=pageNum
                this.load();
            },

            Edit_save(){ //更新备注
                var call_label=this.Edit_data_form.call_label
                //如果标记为了未完成标签并且当前没有标记，则复制一遍发给后端，后端收到后重新分配任务
                if((call_label=='label1'||call_label=='label2'||call_label=='label3')&&this.IsCall_label==false){
                    this.Edit_data_form.call_label=call_label+call_label
                }
                this.editCallDataOKBtnLoading=true
                request.post("/callrecord/change",this.Edit_data_form).then(res =>{
                    if(res.code === 200){
                        this.$message({
                            type:"success",
                            message:"编辑成功"
                        })
                        this.EditVisible = false  // 关闭弹窗
                        this.load() // 刷新表格的数据
                    }
                    else{
                        this.$message({
                            type:"error",
                            message:res.msg,
                        })
                    }
                    this.editCallDataOKBtnLoading=false

                }).catch(e=> {
                    this.editCallDataOKBtnLoading=false
                    this.$message({
                        type: "error",
                        message: '请求失败,服务器错误',
                    })
                })


            },
            //时间戳转换
            getDate(date, fmt) {
                date =new Date(date)
                if (/(y+)/.test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
                }
                let o = {
                    'M+': date.getMonth() + 1,
                    'd+': date.getDate(),
                    'h+': date.getHours(),
                    'm+': date.getMinutes(),
                    's+': date.getSeconds()
                };
                for (let k in o) {
                    if (new RegExp(`(${k})`).test(fmt)) {
                        let str = o[k] + '';
                        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str));
                    }
                }
                return fmt;
            },
            //时间戳转换（辅助）
            padLeftZero (str) {
                return ('00' + str).substr(str.length);
            },


            clearFilter() {
                this.$refs.callRecordTable.clearFilter();
            },
            call_direction_filter(value,row){
                return row.call_direction===value
            },
            call_label_filter(value,row){
                if(value=="未标记"&&!this.isLabeled(row)){
                    return true
                }
                return row.call_label===value

            },



        },
        components: {

        }
    }
</script>
<style>
    .recordItem {
        margin: 10px;
    }
</style>
